import styled from 'styled-components';
import { isMobile } from '../../utils/device';

const Wrap = styled.div.attrs({
    className: 'pt-[32px] pb-[32px]'
})`
    background: url('');
    background-size: 100%;
    position: relative;
`;

const Outer = styled.div.attrs({
    className: 'w-[1200px] max-md:w-full max-md:px-[12px] mx-auto'
})`
    position: relative;
    z-index: 1;
`;

const Title = styled.div.attrs({
    className: 'text-center text-[70px] max-md:text-[24px] text-[#ABA4FF] mb-[24px] relative'
})`
    -webkit-text-stroke: 2px #000;
    &::before {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_21.png');
        background-size: 100% 100%;
        left: ${isMobile ? '80px' : '350px'};
        top: ${isMobile ? 0 : '16px'};
    }
    &::after {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_20.png');
        background-size: 100% 100%;
        right: ${isMobile ? '80px' : '350px'};
        top: ${isMobile ? 0 : '16px'};
    }
`;

const RowLeft = styled.div.attrs({
    className: 'mb-[24px] flex items-end'
})``;

const RowRight = styled(RowLeft).attrs({
    className: 'justify-end'
})``;

const TextBlock = styled.div.attrs({
    className: 'w-[903px] max-md:w-[250px] rounded-[12px] p-[24px] max-md:p-[12px]'
})`
    border: 1px solid #000;
    background: ${p => p.$right ? '#CAE3FF' : '#9FE8FF'};
`;

const ArrowRight = styled.img.attrs({
    className: 'w-[74px] max-md:w-[36px] ml-[32px] max-md:ml-[24px]'
})``;

const ArrowLeft = styled.img.attrs({
    className: 'w-[74px] max-md:w-[36px] mr-[32px] max-md:mr-[24px]'
})``;

const TextHeader = styled.div.attrs({
    className: 'flex items-center justify-between'
})``;

const TextTitle = styled.div.attrs({
    className: 'text-[30px] max-md:text-[16px] text-black'
})``;

const TextTitleIcon = styled.img.attrs({
    className: 'max-md:w-[54px]'
})``;

const TextDesc = styled.div.attrs({
    className: 'text-[18px] max-md:text-[12px] text-black mt-[12px]'
})``;

const BgImg = styled.img.attrs({
    className: ''
})`
    position: absolute;
    top: 0;
    left: 0;
`;

export default function UseWay() {
    return (
        <Wrap id="use">
            <BgImg src='/imgs/group_19.png' width="100%"></BgImg>
            <Outer>
                <Title>How to buy</Title>
                <RowLeft>
                    <TextBlock>
                        <TextHeader>
                            <TextTitle>CREATE A WALLET</TextTitle>
                            <TextTitleIcon src='/imgs/group_12.png'></TextTitleIcon>
                        </TextHeader>
                        <TextDesc>Download And Set Up Your Wallet Of Choice (We Recommend Metamask.io). You Can Download The Extension Or Use The Mobile App At No Cost. Ensure To Keep Your Recovery Phrase In A Secure Location.</TextDesc>
                    </TextBlock>
                    <ArrowRight src='/imgs/group_11.png'></ArrowRight>
                </RowLeft>

                <RowRight>
                    <ArrowLeft src='/imgs/group_10.png'></ArrowLeft>
                    <TextBlock $right>
                        <TextHeader>
                            <TextTitle>GET SOME ETH</TextTitle>
                            <TextTitleIcon src='/imgs/group_12.png'></TextTitleIcon>
                        </TextHeader>
                        <TextDesc>If Your Wallet Doesn’t Have Eth, You Can Purchase It Directly Through Metamask, Transfer It From Another Wallet, Or Buy It On A Centralized Exchange And Then Withdraw It To Your Wallet.</TextDesc>
                    </TextBlock>
                </RowRight>

                <RowLeft>
                    <TextBlock>
                        <TextHeader>
                            <TextTitle>GO TO UNISWAP</TextTitle>
                            <TextTitleIcon src='/imgs/group_12.png'></TextTitleIcon>
                        </TextHeader>
                        <TextDesc>Visit App.uniswap.org (Metamask Mobile Features An Integrated Browser) To Connect Your Wallet. Then Paste The $APU Address Into The Field To Indicate Which Coin You Wish To Exchange Your Eth For.</TextDesc>
                    </TextBlock>
                    <ArrowRight src='/imgs/group_11.png'></ArrowRight>
                </RowLeft>

                <RowRight>
                    {/* <ArrowLeft src='/imgs/group_10.png'></ArrowLeft> */}
                    <TextBlock $right>
                        <TextHeader>
                            <TextTitle>SWAP ETH FOR $PHRYGES</TextTitle>
                            <TextTitleIcon src='/imgs/group_12.png'></TextTitleIcon>
                        </TextHeader>
                        <TextDesc>Choose The Amount You Wish To Trade, Click “swap,” And Confirm The Transaction. When Your Wallet Prompts You, Confirm The Action. Congratulations, You Are Now A Proud Holder Of $PHRYGES.</TextDesc>
                    </TextBlock>
                </RowRight>
            </Outer>
        </Wrap>
    )
}