
import styled from 'styled-components';

const Wrap = styled.div.attrs({
    className: 'bg-white pt-[12px] pb-[64px] max-md:pb-[24px]'
})``;

const Outer = styled.div.attrs({
    className: 'w-[1200px] max-md:w-full max-md:px-[12px] mx-auto'
})``;

const Title = styled.div.attrs({
    className: 'text-[#ABA4FF] text-[50px] max-md:text-[24px]'
})`
    -webkit-text-stroke: 2px #000000;
`;

const Desc = styled.div.attrs({
    className: 'text-[20px] max-md:text-[16px] text-[#000] mt-[12px] mb-[24px]'
})``;

const ImgWrap = styled.div.attrs({
    className: 'flex max-md:block justify-between'
})``;

const WikiImg = styled.img.attrs({
    className: 'w-[366px] max-md:w-full max-md:mb-[12px]'
})``;


export default function Wiki() {
    return (
        <Wrap id='about'>
            <Outer>
                <Title>What is ‘The Phryges’</Title>
                <Desc>The Phryges, the Mascot Of Olympic Games Paris 2024,is derived from the Phrygian hat that symbolizes freedom. Through the Phryges, we can experience the spirit and culture of the Olympic Games together. This symbolic culture is in line with the concept of memes, free trade, rejection of lies, rejection of FUD. Let all memes be engraved with The Phryges, join the new meme revolution, and make memes great again!</Desc>
                <Title>The Phryges MEME Revolution</Title>
                <Desc>The future belongs to those who push boundaries and usher in a new era of finance and technology. Many successful meme coins like $pepe, $doge, $shib, $wif etc., have been born with freedom. Let The Phryges work with these successful meme coins to join the Olympic games and build a free and more inclusive meme world.</Desc>
                <ImgWrap>
                    <WikiImg src='/imgs/group_146.png'></WikiImg>
                    <WikiImg src='/imgs/group_147.png'></WikiImg>
                    <WikiImg src='/imgs/group_145.png'></WikiImg>
                </ImgWrap>
            </Outer>
        </Wrap>
    )
}