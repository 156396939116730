import styled from 'styled-components';
import { isMobile } from '../../utils/device';

const Wrap = styled.div.attrs({
    className: 'w-[1200px] max-md:w-full max-md:px-[12px] mx-auto pt-[24px]'
})``;

const Title = styled.div.attrs({
    className: 'text-center text-[70px] max-md:text-[24px] text-[#ABA4FF] mb-[24px] relative'
})`
    -webkit-text-stroke: ${isMobile ? '1px #FFFFFF' : '4px #FFFFFF'};
    &::before {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_21.png');
        background-size: 100% 100%;
        left: ${isMobile? '10px' : '140px'};
        top: ${isMobile ? 0 : '16px'};
    }
    &::after {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_20.png');
        background-size: 100% 100%;
        right: ${isMobile? '10px' : '140px'};
        top: ${isMobile ? 0 : '16px'};
    }
`;

const ImgRow = styled.div.attrs({
    className: 'bg-[#00A4FF] rounded-[32px] flex justify-between p-[24px] max-md:p-[12px] mb-[24px]'
})`
    border: 1px solid #000;
`;

const ImgBox = styled.div.attrs({
    className: 'w-[324px] max-md:w-[30%]'
})`
`;

const ImgEle = styled.img.attrs({
    className: 'w-full max-md:rounded-[12px]'
})``;

const ImgName = styled.div.attrs({
    className: 'text-white text-[28px] max-md:text-[16px] mt-[12px] text-center'
})``;

export default function Jobs() {
    return (
        <Wrap id="world">
            <Title>Join The Phryges World</Title>
            <ImgRow>
                <ImgBox>
                    <ImgEle src='/imgs/group_01.png'></ImgEle>
                    <ImgName>Vitalik</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_03.png'></ImgEle>
                    <ImgName>Elon</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_02.png'></ImgEle>
                    <ImgName>CZ</ImgName>
                </ImgBox>
            </ImgRow>
            <ImgRow>
                <ImgBox>
                    <ImgEle src='/imgs/group_05.png'></ImgEle>
                    <ImgName>Pepe</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_06.png'></ImgEle>
                    <ImgName>Trump</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_07.png'></ImgEle>
                    <ImgName>doge</ImgName>
                </ImgBox>
            </ImgRow>
            <ImgRow>
                <ImgBox>
                    <ImgEle src='/imgs/group_08.png'></ImgEle>
                    <ImgName>Shib</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_09.png'></ImgEle>
                    <ImgName>wif</ImgName>
                </ImgBox>
                <ImgBox>
                    <ImgEle src='/imgs/group_04.png'></ImgEle>
                    <ImgName>pajamas</ImgName>
                </ImgBox>
            </ImgRow>
        </Wrap>
    )
}