import styled from 'styled-components';
import { isMobile } from '../../utils/device';

const Wrap = styled.div.attrs({
    className: 'pb-[64px] pt-[32px] max-md:pb-[12px]'
})`
    background: url('/imgs/Group_34088.png') no-repeat;
    background-size: 100%;
    background-position: center bottom;
`;

const Outer = styled.div.attrs({
    className: 'w-[1200px] mx-auto max-md:w-full max-md:px-[12px]'
})``;

const Title = styled.div.attrs({
    className: 'text-center text-[70px] max-md:text-[24px] text-[#ABA4FF] mb-[24px] relative'
})`
    -webkit-text-stroke: ${isMobile ? '1px #FFFFFF' : '4px #FFFFFF'};
    &::before {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_21.png');
        background-size: 100% 100%;
        left: ${isMobile ? '90px' : '380px'};
        top: ${isMobile ? 0 : '16px'};
    }
    &::after {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_20.png');
        background-size: 100% 100%;
        right: ${isMobile ? '90px' : '380px'};
        top: ${isMobile ? 0 : '16px'};
    }
`;

const SubTitle = styled.div.attrs({
    className: 'text-white text-[32px] text-center mt-[500px] max-md:text-[18px] max-md:mt-[30vw]'
})`
    -webkit-text-stroke: 1px #000000;
`;

const ImgRow = styled.div.attrs({
    className: 'bg-[#00A4FF] rounded-[32px] flex justify-between p-[24px] mb-[24px]'
})`
    border: 1px solid #000;
`;

const ContactBox = styled.div.attrs({
    className: 'flex items-center justify-center mt-[24px] max-md:mt-[8px]'
})`
`;

const GridImgBox = styled.div.attrs({
    className: 'relative mt-[160px] max-md:mt-[30px]'
})`
`;

const StepText = styled.div.attrs({
    className: 'text-[#78F559] text-[50px] max-md:text-[24px] absolute'
})`
    -webkit-text-stroke: 1px #000000;
`;

const StepTextOne = styled(StepText).attrs({
    className: 'left-[220px] max-md:left-[6%] bottom-[100px] max-md:bottom-[11%]'
})`
`;

const StepTextTwo = styled(StepText).attrs({
    className: 'left-[520px] max-md:left-[37%] bottom-[360px] max-md:bottom-[45%]'
})`
`;

const StepTextThree = styled(StepText).attrs({
    className: 'right-[200px] max-md:right-[6%] top-[100px] max-md:top-[12%]'
})`
`;

const GridImg = styled.img.attrs({
    className: 'block mx-auto'
})``;

const PlaneImg = styled.img.attrs({
    className: 'max-md:w-[24px] cursor-pointer'
})``;

const PlaneText = styled.div.attrs({
    className: 'text-[24px] max-md:text-[12px] text-white ml-[10px] cursor-pointer'
})``;

const StepBox = styled.div.attrs({
    className: 'bg-white rounded-[12px] w-[398px] max-md:w-[110px] p-[12px] max-md:p-[4px] text-[24px] max-md:text-[10px] text-black absolute'
})`
    border: 1px solid #000;
`;

const StepBoxOne = styled(StepBox).attrs({
    className: 'left-[12px] max-md:left-[0%] bottom-[300px] max-md:bottom-[36%]'
})`
`;

const StepBoxTwo = styled(StepBox).attrs({
    className: 'left-[300px] bottom-[540px] max-md:left-[33%] max-md:bottom-[68%]'
})`
    
`;

const StepBoxThree = styled(StepBox).attrs({
    className: 'top-[-120px] right-[180px] max-md:right-[1%] max-md:top-[36%]'
})`
`;

export default function Jobs() {
    
    function onRouterPlane() {
        window.location.href = 'https://t.me/PhrygesETH';
    }

    return (
        <Wrap id="road">
            <Outer>
                <Title>Roadmap</Title>
                <GridImgBox>
                    <GridImg src='/imgs/group_16.png'></GridImg>
                    <StepTextOne>Phase 1</StepTextOne>
                    <StepTextTwo>Phase 2</StepTextTwo>
                    <StepTextThree>Phase 3</StepTextThree>
                    <StepBoxOne>Launch<br />DEX listings<br />1,000+ holders<br />$PHRYGES community takeover</StepBoxOne>
                    <StepBoxTwo>CEX listings<br />$PHRYGES normie takeover<br />Strategic partnerships<br />10,000+ holders</StepBoxTwo>
                    <StepBoxThree>The Phryges NFT collections $PHRYGES world domination</StepBoxThree>
                </GridImgBox>
                <SubTitle>Join into The Phryges Freedom World</SubTitle>
                <ContactBox>
                    <PlaneImg onClick={() => onRouterPlane()} src='/imgs/group_17.png'></PlaneImg>
                    <PlaneText onClick={() => onRouterPlane()}>JOIN US</PlaneText>
                </ContactBox>
            </Outer>
        </Wrap>
    )
}