import styled from 'styled-components';
import { useState } from 'react';

const Wrap = styled.div.attrs({
    className: 'max-md:px-[12px]'
})`
`;

const Outer = styled.div.attrs({
    className: 'px-[32px] max-md:px-[12px] w-[1200px] max-md:w-full h-[104px] max-md:h-[46px] flex items-center mx-auto'
})`
    background: url('/imgs/Group_34049.png');
    background-size: 100% 100%;
`;

const Logo = styled.img.attrs({
    className: 'w-[140px] max-md:w-[80px] mr-[120px] max-md:mr-[32px]'
})``;

const MenuWrap = styled.div.attrs({
    className: 'flex text-[18px] max-md:hidden'
})``;

const MobileMenuWrap = styled.div.attrs({
    className: 'text-[14px] hidden max-md:flex'
})``;

const MenuItem = styled.div.attrs({
    className: 'mr-[24px] cursor-pointer'
})`
    -webkit-text-stroke: 1px #000000;
    position: relative;
    text-indent: -2px;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -24px;
        width: 48px;
        height: 6px;
        background: url('/imgs/menu-active.png');
        background-size: 100% 100%;
        display: ${p => p.$active ? 'block' : 'none'};
    }
`;

const MobileMenuItem = styled.div.attrs({
    className: 'mr-[24px]'
})`
    -webkit-text-stroke: 1px #000000;
`;

// const MenuWrap = styled.div.attrs({
//     className: ''
// })``;

export default function Jobs() {
    const [activeMenu, setActiveMenu] = useState('home');
    function onRouterPage(url) {
        if (url.startsWith('http')) {
            window.location.href = url;
        } else {
            window.scrollTo({
                top: document.getElementById(url).getBoundingClientRect().top,
                behavior: 'smooth'
            })
        }
    }

    return (
        <Wrap>
            <Outer>
                <Logo src='/imgs/Group_34080.png'></Logo>
                <MenuWrap>
                    <MenuItem $active={activeMenu === 'home'}>Home</MenuItem>
                    <MenuItem onClick={() => onRouterPage('about')}>About</MenuItem>
                    <MenuItem onClick={() => onRouterPage('world')}>The Phryges World</MenuItem>
                    <MenuItem onClick={() => onRouterPage('use')}>How to buy</MenuItem>
                    <MenuItem onClick={() => onRouterPage('omics')}>Tokenomics</MenuItem>
                    <MenuItem onClick={() => onRouterPage('road')}>Roadmap</MenuItem>
                    <MenuItem onClick={() => onRouterPage('https://t.me/PhrygesETH')}>Community</MenuItem>
                    <MenuItem onClick={() => onRouterPage('https://x.com/ThePhryges')}>Twitter</MenuItem>
                </MenuWrap>
                <MobileMenuWrap>
                    <MobileMenuItem onClick={() => onRouterPage('https://www.dextools.io/app/cn/ether/pair-explorer/0x967c9b937ff4349ff69144e338d2b650d90a6178?t=1716517276444')}>Chart</MobileMenuItem>
                    <MobileMenuItem onClick={() => onRouterPage('https://t.me/PhrygesETH')}>Telegram</MobileMenuItem>
                    <MobileMenuItem onClick={() => onRouterPage('https://x.com/ThePhryges')}>Twitter</MobileMenuItem>
                </MobileMenuWrap>
            </Outer>
        </Wrap>
    )
}