import styled from 'styled-components';
import { isMobile } from '../../utils/device';

const Wrap = styled.div.attrs({
    className: 'relative'
})``;

const Outer = styled.div.attrs({
    className: 'w-[1200px] mx-auto max-md:w-full max-md:px-[12px]'
})``;

const Title = styled.div.attrs({
    className: 'text-center text-[70px] max-md:text-[24px] text-[#ABA4FF] mb-[24px] relative'
})`
    -webkit-text-stroke: ${isMobile ? '1px #FFFFFF' : '4px #FFFFFF'};
    &::before {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_21.png');
        background-size: 100% 100%;
        left: ${isMobile ? '80px' : '350px'};
        top: ${isMobile ? 0 : '16px'};
    }
    &::after {
        content: '';
        position: absolute;
        width: ${isMobile ? '27px' : '61px'};
        height: ${isMobile ? '36px' : '81px'};
        background: url('/imgs/group_20.png');
        background-size: 100% 100%;
        right: ${isMobile ? '80px' : '350px'};
        top: ${isMobile ? 0 : '16px'};
    }
`;

const ImgBox = styled.div.attrs({
    className: 'relative mt-[32px]'
})``;

const ImgEle = styled.img.attrs({
    className: 'absolute left-0 top-[64px] max-md:top-[16px] max-md:w-[100px]'
})``;

const ImgEleRight = styled.img.attrs({
    className: 'ml-auto max-md:w-[240px]'
})``;

const CloudImg = styled.img.attrs({
    className: 'absolute right-0 top-[24px]'
})``;

export default function Jobs() {
    return (
        <Wrap id="omics">
            <CloudImg src='/imgs/group_18.png'></CloudImg>
            <Outer>
                <Title>Tokenomics</Title>
                <ImgBox>
                    <ImgEle src="/imgs/group_13.png"></ImgEle>
                    <ImgEleRight src="/imgs/group_14.png"></ImgEleRight>
                </ImgBox>
            </Outer>
        </Wrap>
    )
}