import { Outlet } from "react-router-dom";
import styled from 'styled-components';

const Wrap = styled.div``;

export default function Layout() {
    return (
        <Wrap>
            <Outlet></Outlet>
        </Wrap>
    )
}