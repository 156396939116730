import styled from 'styled-components';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useNavigate } from 'react-router-dom';
import TopNav from '../../components/top-nav';
import Wiki from '../../components/wiki';
import Jobs from '../../components/jobs';
import UseWay from '../../components/use-way';
import Omics from '../../components/omics';
import Road from '../../components/road';

const Wrap = styled.div.attrs({
    className: 'relative w-full bg-[#65C8FF]'
})`
`;

export default function Home() {
    const navigate = useNavigate();

    return (
        <Wrap>
            <TopNav></TopNav>
            <Wiki></Wiki>
            <Jobs></Jobs>
            <UseWay></UseWay>
            <Omics></Omics>
            <Road></Road>
        </Wrap>
    )
}