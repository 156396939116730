import styled from 'styled-components';
import Navigation from '../navigation';

const Wrap = styled.div.attrs({
    className: 'relative pt-[32px] pb-[32px]'
})`
    background: url('/imgs/Group_34085.png');
    background-size: 100% 100%;
`;

const NavImg = styled.img.attrs({
    className: 'block mx-auto mt-[12px] max-md:w-[280px]'
})``;

const Title = styled.div.attrs({
    className: 'text-white text-[100px] max-md:text-[32px] font-bold text-center mt-[32px]'
})`
    -webkit-text-stroke: 1px #000000;
`;

const TitleText = styled.span.attrs({
    className: ''
})`
    color: ${p => p.$strong ? '#ABA4FF' : '#FFFFFF'};
`;


const SubTitle = styled.div.attrs({
    className: 'text-white text-center text-[40px] max-md:text-[20px]'
})`
    -webkit-text-stroke: 2px #000000;
`;

const LaunchBtn = styled.div.attrs({
    className: 'bg-[#78F559] w-[295px] max-md:w-[120px] h-[69px] max-md:h-[44px] leading-[69px] max-md:leading-[40px] mx-auto text-center text-[26px] max-md:text-[16px] mt-[60px] max-md:mt-[24px]'
})`
    border: 2px solid #000000;
    border-radius: 69px;
    -webkit-text-stroke: 1px #000000;
    cursor: pointer;
`;

export default function TopNav() {

    function onLaunch() {
        window.location.href = 'https://www.dextools.io/app/cn/ether/pair-explorer/0x967c9b937ff4349ff69144e338d2b650d90a6178?t=1716517276444';
    }

    return (
        <Wrap>
            <Navigation></Navigation>
            <Title>
                <TitleText>The Mascot&nbsp;</TitleText>
                <TitleText $strong>MEME</TitleText>
            </Title>
            <SubTitle>Of Olympic Games Paris 2024</SubTitle>
            <NavImg src='/imgs/group_15.png'></NavImg>
            <LaunchBtn onClick={onLaunch}>CHART</LaunchBtn>
        </Wrap>
    )
}